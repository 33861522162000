.app__events {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    height: auto;
}

.app__events-container {
    background: var(--color-light-tan);
    max-width: 900px;
    color: var(--color-black)
}

.app__events-container h1,
.app__events-container p {
    margin: 20px;
}

.app_events__card-container {
    display: flex;
    flex-direction: column;
}

.app__events_event-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
}

.app__events_event-card img {
    width: 200px;
    height: auto;
    margin: 0 1rem 1rem 0;
}

.app__events_event-card_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.app__events_event-card_content_link {
    color: blue;
    margin: 0 20px 0 0;
}

@media screen and (max-width: 650px) {
    .app__events_event-card img {
        width: 80px;
    }
}