@import url('https://fonts.googleapis.com/css2?family=Neuton:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --font-base: 'Neuton', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-black: #010606;
  --color-gray: #545454;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-cyan: #1B9AAA;
  --color-light-cyan: #20B9CC;
  --color-dark-cyan: #105D66;
  --color-green: #01BF71;
  --color-light-tan: #F5F1E3;
  --color-dark-tan: #DDDBCB;
  --color-sand: #7E7952;
  --color-transparent: transparent;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
