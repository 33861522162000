.app__hero-video {
    height: 90vh;
    position: relative;
    -webkit-filter: grayscale(100%);
    filter: grayscale(50%);
}

.app__hero-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__hero-overlay {
    position: absolute;
    top: 95%;
    z-index: 1;
    width: 100%;
    color: #fff;
    font-size: 3em;
    margin-top: -100px;
    display: flex;
    align-items: 'center';
    justify-content: center;
  }