.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 2rem;
    position: fixed;
    z-index: 1;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-white);
}

.app__navbar-logo_title {
    font-family: var(--font-alt);
    font-size: 35px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app__navbar-link_item {
    color: var(--color-white);
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-link_item:hover {
    color: var(--color-cyan);
    transition: 0.3s ease-out;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-cyan);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.app__navbar-smallscreen_link_items {
    margin: 0.75rem;
    cursor: pointer;
    color: var(--color-cyan);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_link_items:hover {
    color: var(--color-white);
}

@media screen and (max-width: 2000px) {
    .app_navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }

    .app_navbar-logo img {
        width: 110px;
    }
}